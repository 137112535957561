<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
      aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0 d-none d-md-block" routerLinkActive="active" [routerLink]="['/dashboard']">
      <img *ngIf="sidA" src="../../../assets/img/renmoney-logo.svg" class="navbar-brand-img" alt="app logo">
      <img *ngIf="bankAdmin && !profile?.image" src="../../../assets/img/renmoney-logo.svg" class="navbar-brand-img"
        alt="app logo">
      <img *ngIf="bankAdmin && profile?.image" src="{{profile?.image | img}}" class="navbar-brand-img" alt="app logo">
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">

      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="user avatar avatar-sm rounded-circle">
              <img class="img-responsive" alt="Image placeholder" src="{{profile?.image | img}}">
              <!-- <img class="image-responsive" alt="Image placeholder" src="./assets/img/theme/team-1-800x800.jpg"> -->
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="fa ni-single-02"></i>
            <span>My profile</span>
          </a>
          <div class="dropdown-divider"></div>
          <a (click)="onLogout()" class="dropdown-item">
            <i class="fa ni-user-run"></i>
            <span>Logout</span>
          </a>
          <button *ngIf="pwa.promptEvent" class="dropdown-item" (click)="installPwa()">
            Install App
          </button>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active" [routerLink]="['/dashboard']">
              <img alt="app logo" src="../../../assets/img/brand/argon-white.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <!-- Navigation -->
      <ul class="navbar-nav">
        <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
          <a *ngIf="menuItem.type === 'link'" routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
            <i class="fa {{menuItem.icon}} {{menuItem.iconColor}} "></i>
            <span class="nav-link-text">{{menuItem.title}}</span>
          </a>
          <!-- SUB -->
          <a *ngIf="menuItem.type === 'sub'" href="javascript:void(0)" (click)="toggleNav(menuItem)" class="nav-link"
            role="button" aria-expanded="true">
            <i class="fa {{menuItem.icon}} {{menuItem.iconColor}}"></i>
            <span class="mr-auto">
              {{menuItem.title}}
            </span>
            <i *ngIf="menuItem.children && !menuItem.active" class="text-right text-primary fa fa-chevron-down"></i>
            <i *ngIf="menuItem.children && menuItem.active" class="text-right text-primary fa fa-chevron-up"></i>
            <!-- <span class="fill-remaining-space"></span> -->
          </a>
          <ul *ngIf="menuItem.active" class="nav nav-sm flex-column">
            <li *ngFor="let childItem of menuItem.children" class="nav-item">
              <a class="nav-link" [routerLink]="[childItem.path]">
                <i class="fa {{childItem.icon}} {{childItem.iconColor}}"></i>
                <span class="mr-auto">
                  {{childItem?.title}}
                </span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Link -->
<!-- <li class="nav-item">
  <a class="nav-link" href="../../pages/calendar.html">
    <i class="ni ni-calendar-grid-58 text-red"></i>
    <span class="nav-link-text">Calendar</span>
  </a>
</li> -->

<!-- Multi Level -->
<!-- <li class="nav-item">
  <a class="nav-link active" href="#navbar-dashboards" data-toggle="collapse" role="button" aria-expanded="true"
    aria-controls="navbar-dashboards">
    <i class="ni ni-shop text-primary"></i>
    <span class="nav-link-text">Dashboards</span>
  </a>
  <div class="collapse show" id="navbar-dashboards">
    <ul class="nav nav-sm flex-column">
      <li class="nav-item">
        <a href="../../pages/dashboards/dashboard.html" class="nav-link">Dashboard</a>
      </li>
      <li class="nav-item">
        <a href="../../pages/dashboards/alternative.html" class="nav-link active">Alternative</a>
      </li>
    </ul>
  </div>
</li> -->
<!-- Divider -->
<!-- <hr class="my-3"> -->
<!-- Then Logout -->
